var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [!_vm.isViewOnly ? _c('el-button', {
    staticClass: "mb-4",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "size": "mini"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.triggerShowUpload();
      }
    }
  }, [_vm._v(" Upload "), _c('i', {
    staticClass: "el-icon-upload el-icon-right"
  })]) : _vm._e()], 1), _c('el-table', {
    attrs: {
      "height": "550",
      "data": _vm.data
    }
  }, [_vm._l(_vm.columns, function (column, index) {
    return _c('el-table-column', {
      key: index,
      attrs: {
        "sortable": "columns[index].sortable",
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    });
  }), _c('el-table-column', {
    attrs: {
      "label": "",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.isImage(scope.row) ? _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openLightBox(scope.row);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "image",
            "size": 20
          }
        })], 1) : _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDoc(scope.row);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "file-eye",
            "size": 20
          }
        })], 1), !_vm.isViewOnly ? _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.confirmDelete(scope.row);
            }
          }
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 20
          }
        })], 1) : _vm._e(), !scope.row.default_file ? _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.markFileAsDefault(scope.row.id);
            }
          }
        }, [_vm._v(" Add to default ")]) : _vm._e()];
      }
    }])
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }