var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Request Access",
      "visible": _vm.isModalOpen,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isModalOpen = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "form": _vm.form,
      "model": _vm.form,
      "rules": _vm.rules,
      "status-icon": ""
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Email Address",
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email Address",
      "type": "email",
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Please let us know more about your company:",
      "prop": "cover_letter"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "",
      "type": "textarea",
      "rows": "5",
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.form.cover_letter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cover_letter", $$v);
      },
      expression: "form.cover_letter"
    }
  })], 1), _c('el-form-item', {
    staticClass: "text-right"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v("Send Request")]), _c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }