var render = function () {
  var _vm$authUser, _vm$authUser$organisa, _vm$authUser2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "topbar"
  }, [_c('nav', {
    staticClass: "navbar top-navbar navbar-expand-md navbar-dark"
  }, [_c('div', {
    staticClass: "navbar-header"
  }, [_c('div', {
    staticClass: "navbar-brand"
  }, [_c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": "/"
    }
  }, [_c('b', {
    staticClass: "logo-icon"
  }, [_c('img', {
    staticClass: "toplogo",
    attrs: {
      "src": "/images/logo.svg",
      "alt": "Illuminate - Green"
    }
  })])])], 1), _c('div', {
    staticClass: "topbartoggler d-block d-md-none waves-effect waves-light"
  })]), _c('div', {
    staticClass: "navbar-collapse collapse",
    attrs: {
      "id": "navbarSupportedContent"
    }
  }, [_c('div', {
    staticClass: "vertical-line"
  }), _c('ul', {
    staticClass: "navbar-nav mr-auto"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('div', {
    staticClass: "orgname"
  }, [_vm._v(" " + _vm._s((_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : (_vm$authUser$organisa = _vm$authUser.organisation) === null || _vm$authUser$organisa === void 0 ? void 0 : _vm$authUser$organisa.name) + " ")])])]), _c('ul', {
    staticClass: "navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('el-dropdown', {
    staticClass: "nav-link",
    attrs: {
      "trigger": "click"
    }
  }, [_c('span', {
    staticClass: "el-dropdown-link"
  }, [_c('mdicon', {
    staticClass: "text-success",
    attrs: {
      "name": "account-circle-outline",
      "size": 24
    }
  }), _c('span', {
    staticClass: "ml-2 font-medium d-none d-sm-inline-block text-muted"
  }, [_vm._v(" " + _vm._s((_vm$authUser2 = _vm.authUser) === null || _vm$authUser2 === void 0 ? void 0 : _vm$authUser2.name) + " "), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })])], 1), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', [_c('router-link', {
    attrs: {
      "to": "/profile"
    }
  }, [_vm._v(" Profile ")])], 1), _c('el-dropdown-item', [_c('el-link', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Sign Out ")])], 1)], 1)], 1)], 1)])])]), _c('SidebarMenu')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }