var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-row', [_c('el-col', [_c('el-button', {
    attrs: {
      "type": "plain",
      "icon": "el-icon-arrow-left"
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Go Back ")]), _c('el-button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.checkFormValidation();
      }
    }
  }, [_vm._v(" Send invitation ")])], 1)], 1), _vm.isAlertShow ? _c('alert', {
    attrs: {
      "message": _vm.alertObj.message,
      "type": _vm.alertObj.type
    }
  }) : _vm._e(), _vm._l(_vm.invitationForm, function (item, index) {
    return _c('el-row', {
      key: index
    }, [_c('el-col', [_c('el-card', {
      attrs: {
        "shadow": "never"
      }
    }, [_c('div', {
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [_c('span', [_vm._v("#" + _vm._s(index + 1))]), _c('el-button', {
      staticStyle: {
        "float": "right"
      },
      attrs: {
        "icon": "el-icon-delete",
        "type": "danger",
        "plain": "",
        "size": "small"
      },
      on: {
        "click": function ($event) {
          return _vm.removeOrganisation(index);
        }
      }
    }, [_vm._v(" Remove ")])], 1), _c('el-form', {
      ref: ("invitationForm-" + index),
      refInFor: true,
      attrs: {
        "model": item,
        "label-width": "150px",
        "rules": _vm.rules
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Email",
        "prop": "email"
      }
    }, [_c('el-input', {
      attrs: {
        "placeholder": "Please input email",
        "name": item.email
      },
      model: {
        value: item.email,
        callback: function ($$v) {
          _vm.$set(item, "email", $$v);
        },
        expression: "item.email"
      }
    })], 1), _vm.isSuperAdmin ? _c('el-form-item', {
      attrs: {
        "label": "Type",
        "prop": "org_type_code"
      }
    }, [_c('el-select', {
      attrs: {
        "placeholder": "Select the type",
        "name": item.org_type_code
      },
      model: {
        value: item.org_type_code,
        callback: function ($$v) {
          _vm.$set(item, "org_type_code", $$v);
        },
        expression: "item.org_type_code"
      }
    }, [_c('el-option', {
      attrs: {
        "label": "Supplier",
        "value": "supplier"
      }
    }), _c('el-option', {
      attrs: {
        "label": "Buyer",
        "value": "buyer"
      }
    })], 1)], 1) : _vm.isSupplier && !_vm.isSuperAdmin ? _c('el-form-item', {
      directives: [{
        name: "orgType",
        rawName: "v-orgType",
        value: 'supplier',
        expression: "'supplier'"
      }],
      attrs: {
        "label": "Organisation type",
        "prop": "org_type_code"
      }
    }, [_c('el-select', {
      attrs: {
        "value": "buyer",
        "placeholder": "Select the type",
        "name": item.org_type_code,
        "disabled": ""
      }
    }, [_c('el-option', {
      attrs: {
        "label": "Buyer",
        "value": "buyer"
      }
    }, [_vm._v("Buyer")])], 1)], 1) : _vm.isBuyer && !_vm.isSuperAdmin ? _c('el-form-item', {
      directives: [{
        name: "orgType",
        rawName: "v-orgType",
        value: 'buyer',
        expression: "'buyer'"
      }],
      attrs: {
        "value": "supplier",
        "label": "Organisation type",
        "prop": "org_type_code"
      }
    }, [_c('el-select', {
      attrs: {
        "value": "supplier",
        "placeholder": "Select the type",
        "name": item.org_type_code,
        "disabled": ""
      }
    }, [_c('el-option', {
      attrs: {
        "label": "Supplier",
        "value": "supplier"
      }
    })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), _c('el-row', [_c('el-col', [_c('el-button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "icon": "el-icon-plus"
    },
    on: {
      "click": _vm.addOrganisation
    }
  }, [_vm._v(" Add more ")])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }