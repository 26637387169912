var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-12 mx-auto"
  }, [_c('div', {
    staticClass: "card card-reset my-3"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('nav', {
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('ol', {
    staticClass: "breadcrumb bg-white pl-0"
  }, [_c('li', {
    staticClass: "breadcrumb-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "breadcrumb-item active",
    attrs: {
      "aria-current": "page"
    }
  }, [_vm._v("About Us")])])]), _c('h1', {
    staticClass: "h3 card-title page-title text-success"
  }, [_vm._v("About Us")]), _c('p', [_vm._v(" Illuminate has used its experience to build a tender management system for use in State Government hospital tenders with the aim of streamlining the process and allowing States to gain lower prices and improved efficiency. Illuminate already has a track record in the successful development of systems in pharmaceutical pricing with another system, RED. This is now widely used by the pharmaceutical industry. ")]), _c('p', [_vm._v(" The GREEN system has been built over the past 5 years. GREEN has been developed from the perspective of the State buying groups. The two main objectives are to improve efficiency of the process and gain lower prices. It also improves record keeping and the integrity of the procurement process, is adaptable and requires minimal work for initiation. The system has a range of design features which support these objectives. ")]), _c('p', [_vm._v(" Illuminate, believe GREEN is a unique and practical solution to the management of State tenders which can be implemented easily to enable quick wins for the States pharmaceutical budget as well as take pressure off staff working on the tenders by reducing their workload. ")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }