var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "bottom-navbar justify-content-start",
    attrs: {
      "toggleable": "lg",
      "type": "dark",
      "align": "start"
    }
  }, [_vm.isUserLocked ? [_c('b-navbar-nav', [_c('b-nav-item', {
    class: {
      active: _vm.currentRouteTitle === 'Create Organisation'
    },
    attrs: {
      "to": "create"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "office-building",
      "size": 24
    }
  }), _vm._v(" Create New Organisation ")], 1)], 1)] : [_c('b-navbar-nav', [_c('b-nav-item', {
    class: {
      active: _vm.currentRouteTitle === 'Dashboard'
    },
    attrs: {
      "to": "/"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "av-timer",
      "size": 24
    }
  }), _vm._v(" Dashboard ")], 1)], 1), _c('b-navbar-nav', [_c('b-nav-item-dropdown', {
    class: {
      active: _vm.currentRouteTitle === 'RFT'
    },
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "note-text",
            "size": 24
          }
        }), _vm._v(" My RFT ")];
      },
      proxy: true
    }])
  }, _vm._l(_vm.myRFTRoute.filter(function (route) {
    return route.visible == true;
  }), function (route, index) {
    return _c('b-dropdown-item', {
      key: index,
      attrs: {
        "to": {
          path: '/rft',
          query: {
            tab: route.query
          }
        },
        "replace": ""
      }
    }, [_vm._v(" " + _vm._s(route === null || route === void 0 ? void 0 : route.title) + " ")]);
  }), 1)], 1), _vm.isBuyer ? _c('b-navbar-nav', [_c('b-nav-item', {
    class: {
      active: _vm.currentRouteTitle === 'Patent Expiry'
    },
    attrs: {
      "to": "/patent-expiry"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "timer-sand",
      "size": 24
    }
  }), _vm._v(" Patent Expiry ")], 1)], 1) : _vm._e(), _c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('b-nav-item-dropdown', {
    class: {
      active: _vm.currentRouteTitle !== 'Dashboard' && _vm.currentRouteTitle !== 'RFT' && _vm.currentRouteTitle !== 'Profile'
    },
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('mdicon', {
          attrs: {
            "name": "cog",
            "size": 24
          }
        }), _vm._v(" "), _c('span', [_vm._v("Settings")])];
      },
      proxy: true
    }])
  }, _vm._l(_vm.settingRoute, function (route, index) {
    var _route$meta;
    return _c('b-dropdown-item', {
      key: index,
      attrs: {
        "to": route === null || route === void 0 ? void 0 : route.path
      }
    }, [_vm._v(" " + _vm._s(route === null || route === void 0 ? void 0 : (_route$meta = route.meta) === null || _route$meta === void 0 ? void 0 : _route$meta.title) + " ")]);
  }), 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }