var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "productSpecForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Description",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 4,
        maxRows: 10
      },
      "placeholder": "Description",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Category",
      "prop": "product_spec_category_id"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Select",
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.form.product_spec_category_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "product_spec_category_id", $$v);
      },
      expression: "form.product_spec_category_id"
    }
  }, _vm._l(_vm.categories, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.category,
        "value": item.id
      }
    });
  }), 1)], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }