var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": "modal-orgproduct-editor",
      "title": "Product Spec. Category Details"
    },
    on: {
      "hidden": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('ProductSpecCategoryEditorForm', {
    ref: "productSpecCategoryEditorForm"
  }), [!_vm.editable ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock"
    }
  }), _vm._v(" Edit")], 1) : _c('div', [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "size": "sm",
      "variant": "outline-info"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset ")], 1), !_vm.creating ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e()], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }