var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-12 mx-auto"
  }, [_c('div', {
    staticClass: "card card-reset my-3"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('nav', {
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('ol', {
    staticClass: "breadcrumb bg-white pl-0"
  }, [_c('li', {
    staticClass: "breadcrumb-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "breadcrumb-item active",
    attrs: {
      "aria-current": "page"
    }
  }, [_vm._v("Privacy Policy")])])]), _c('h1', {
    staticClass: "h3 card-title page-title text-success"
  }, [_vm._v("Privacy Policy")]), _c('p', [_vm._v(" Our company is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines our practices and procedures related to the collection, use, and disclosure of personal information. By using our website or services, you consent to the collection and use of your personal information in accordance with this Privacy Policy. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Collection of Information")]), _c('p', [_vm._v(" We may collect personal information such as your name, address, email address, and phone number. We may also collect non-personal information such as your IP address and browsing history. We may collect this information when you visit our website, use our services, or interact with us in other ways. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Use of Information")]), _c('p', [_vm._v(" We use your personal information to provide our services to you, respond to your inquiries, and improve our website and services. We may also use your information for marketing purposes, such as sending you newsletters or promotional emails. We will always provide you with the option to opt out of such communications. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Disclosure of Information")]), _c('p', [_vm._v(" We do not sell or rent your personal information to third parties. We may share your personal information with our service providers and partners who help us provide our services to you. We may also disclose your personal information if required by law, in response to a court order, or if we believe that such disclosure is necessary to protect our rights or the rights of others. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Security of Information")]), _c('p', [_vm._v(" We take reasonable measures to protect your personal information from unauthorized access, disclosure, and misuse. We use industry-standard security technologies and procedures to protect your information. However, no security system is completely foolproof, and we cannot guarantee the security of your information. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Cookies")]), _c('p', [_vm._v(" We may use cookies and other tracking technologies to collect non-personal information about your use of our website. Cookies are small files that are stored on your device to help us provide a better user experience. You can control cookies through your browser settings. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Links to Other Websites")]), _c('p', [_vm._v(" Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these websites before providing any personal information. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Changes to Privacy Policy")]), _c('p', [_vm._v(" We may update this Privacy Policy from time to time. Any changes will be posted on our website, and we encourage you to review this Privacy Policy periodically. ")]), _c('h2', {
    staticClass: "h4 card-title text-success"
  }, [_vm._v("Contact Us")]), _vm._m(0)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" If you have any questions or concerns about this Privacy Policy, please contact us at "), _c('a', {
    attrs: {
      "href": "mailto:admin@illuminatehc.com.au"
    }
  }, [_vm._v("Contact Support")]), _vm._v(". ")]);
}]

export { render, staticRenderFns }