var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card-group mb-3"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search Product Specification Name, Description ..."
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchHandler.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searching,
      callback: function ($$v) {
        _vm.searching = $$v;
      },
      expression: "searching"
    }
  }, [_vm.searching ? _c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-close"
    },
    on: {
      "click": _vm.clearSearch
    },
    slot: "append"
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'superadmin-only',
      expression: "'superadmin-only'"
    }],
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.handleProductSpecEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "plus",
      "size": 18
    }
  }), _vm._v(" Add New ")], 1)], 1)])])])]), _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "height": "550",
      "data": _vm.data
    },
    on: {
      "sort-change": _vm.SetSorting
    }
  }, [_vm._l(_vm.columns, function (column, index) {
    return _c('el-table-column', {
      key: index,
      attrs: {
        "sortable": "columns[index].sortable",
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    });
  }), _c('el-table-column', {
    attrs: {
      "label": "",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'superadmin-only',
            expression: "'superadmin-only'"
          }],
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.handleProductSpecEdit(scope.row);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "pencil",
            "size": 20
          }
        })], 1), _c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'superadmin-only',
            expression: "'superadmin-only'"
          }],
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.confirmDelete(scope.row);
            }
          }
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 20
          }
        })], 1)];
      }
    }])
  })], 2), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "total, prev, pager, next, jumper",
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "total": _vm.currentTotalPage
    },
    on: {
      "current-change": _vm.SetPageNumber
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "productspec-editor-modal"
  }, [_c('ProductSpecEditorModal', {
    ref: "productSpecEditor"
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }