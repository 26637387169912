var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-content-center"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "page-size": _vm.limit,
      "current-page": _vm.currentPage,
      "layout": "prev, pager, next",
      "total": _vm.total_data,
      "hide-on-single-page": _vm.hide_on_single_page
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _vm.currentPage = $event;
      },
      "next-click": _vm.handleNextClick,
      "prev-click": _vm.handlePrevClick,
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }