var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-guest"
  }, [_c('div', {
    attrs: {
      "id": "main-wrapper"
    }
  }, [_c('Navbar'), _c('div', {
    staticClass: "page-wrapper"
  }, [_c('b-container', {
    staticStyle: {
      "align-items": "center"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('AppMain')], 1), _c('AppFooter')], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }