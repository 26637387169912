var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-container', {
    staticClass: "mt-5 flex justify-content-center align-items-center"
  }, [_c('el-row', [_c('el-col', [_vm.isAlertShow ? _c('alert', {
    attrs: {
      "message": _vm.alert.message,
      "type": _vm.alert.type
    }
  }) : _vm._e(), _c('el-card', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "box-card text-center mt-3"
  }, [_c('h4', [_vm._v(_vm._s(_vm.verificationTitle))]), _c('el-progress', {
    staticClass: "mt-3",
    attrs: {
      "type": "circle",
      "width": 250,
      "percentage": _vm.percentage.progress,
      "status": _vm.percentage.status
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }