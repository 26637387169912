<template>
  <div id="app">
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<script>
require("@/assets/css/styles.scss");
</script>
