var render = function () {
  var _vm$description;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-alert', {
    staticClass: "alert my-3",
    attrs: {
      "type": _vm.type,
      "effect": !_vm.isDark ? 'dark' : 'light'
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    },
    slot: "title"
  }), ((_vm$description = _vm.description) === null || _vm$description === void 0 ? void 0 : _vm$description.length) > 0 ? _vm._t("default", function () {
    return [_c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.description)
      }
    })];
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }