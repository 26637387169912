var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center align-items-center not-found"
  }, [_vm._v(" 404 | Page Not Found ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }