var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "userForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Full name",
      "readonly": !_vm.creating
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "email"
    }
  }, [_c('el-input', {
    ref: "email_input",
    attrs: {
      "placeholder": "Email",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Phone",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Phone number",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Mobile",
      "prop": "mobile"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Mobile number",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobile", $$v);
      },
      expression: "form.mobile"
    }
  })], 1), _vm.creating ? _c('el-form-item', {
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "show-password": "",
      "placeholder": "Password",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1) : _vm._e(), _vm.fromList ? _c('el-form-item', {
    attrs: {
      "label": "Role",
      "prop": "role"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Select Role",
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.form.role,
      callback: function ($$v) {
        _vm.$set(_vm.form, "role", $$v);
      },
      expression: "form.role"
    }
  }, _vm._l(_vm.roles, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }