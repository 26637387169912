var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-forgot my-5"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title text-center"
  }, [_vm._v("Forgot Password")]), _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "forgotForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', [_c('b-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.handleSend();
      }
    }
  }, [_vm._v(" Send Password Reset Link ")])], 1)], 1), _c('p', {
    staticClass: "text-success text-center mt-4"
  }, [_vm.message ? _c('el-alert', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(_vm._s(_vm.message))]) : _vm._e()], 1), _c('hr', {
    staticClass: "mt-4"
  }), _vm._m(0)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-muted text-center mt-4"
  }, [_c('a', {
    staticClass: "text-success",
    attrs: {
      "href": "/"
    }
  }, [_vm._v("Sign in")])]);
}]

export { render, staticRenderFns }