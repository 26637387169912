var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "userForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Medicinal product name",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }