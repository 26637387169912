var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "type": "flex",
      "justify": "space-between"
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h4', {
    staticClass: "page-title text-success"
  }, [_vm._v(_vm._s(_vm.$route.meta.title))])]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-breadcrumb', {
    attrs: {
      "separator": "/"
    }
  }, _vm._l(_vm.$route.matched, function (route, index) {
    return _c('el-breadcrumb-item', {
      key: index,
      attrs: {
        "to": route.path
      }
    }, [_vm._v(" " + _vm._s(route.meta.title) + " ")]);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }