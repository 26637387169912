var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('aside', {
    class: 'left-sidebar ' + _vm.leftSidebarClass()
  }, [_c('div', {
    staticClass: "scroll-sidebar"
  }, [_c('nav', {
    staticClass: "sidebar-nav"
  }, [_c('ul', {
    attrs: {
      "id": "sidebarnav"
    }
  }, _vm._l(_vm.routes, function (route) {
    var _route$meta, _route$meta2;
    return _c('li', {
      key: route.path,
      class: 'sidebar-item ' + _vm.selectedMenu(route),
      attrs: {
        "item": route,
        "base-path": route.path
      }
    }, [_c('a', {
      staticClass: "sidebar-link has-arrow waves-effect waves-dark",
      attrs: {
        "href": route.path,
        "aria-expanded": "false"
      }
    }, [_c('mdicon', {
      staticClass: "text-success",
      attrs: {
        "name": route === null || route === void 0 ? void 0 : (_route$meta = route.meta) === null || _route$meta === void 0 ? void 0 : _route$meta.icon,
        "size": 24
      }
    }), _c('span', {
      staticClass: "hide-menu"
    }, [_vm._v(_vm._s(route === null || route === void 0 ? void 0 : (_route$meta2 = route.meta) === null || _route$meta2 === void 0 ? void 0 : _route$meta2.title) + " ")])], 1)]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }