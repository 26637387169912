var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-12 mx-auto"
  }, [_c('div', {
    staticClass: "card card-reset my-3"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('nav', {
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('ol', {
    staticClass: "breadcrumb bg-white pl-0"
  }, [_c('li', {
    staticClass: "breadcrumb-item"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")])], 1), _c('li', {
    staticClass: "breadcrumb-item active",
    attrs: {
      "aria-current": "page"
    }
  }, [_vm._v("Contact Support")])])]), _c('h1', {
    staticClass: "h3 card-title page-title text-success"
  }, [_vm._v("Contact Support")]), _vm._m(0)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Please feel free to contact "), _c('a', {
    attrs: {
      "href": "mailto:samaratunga@illuminatehc.com.au"
    }
  }, [_vm._v("samaratunga@illuminatehc.com.au")])]);
}]

export { render, staticRenderFns }