var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-tabs', {
    attrs: {
      "tab-position": "top",
      "type": "border-card"
    },
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "name": "Detail"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": 18
    }
  }), _vm._v(" Detail ")], 1), _c('OrgDetail', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-show',
      expression: "'organisation-show'"
    }]
  })], 1), _vm.isSupplier ? _c('el-tab-pane', {
    attrs: {
      "name": "Product Default File",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "file-document-outline",
      "size": 18
    }
  }), _vm._v(" Product Files ")], 1), _c('OrgProductDefaultFile')], 1) : _vm._e(), _vm.isSupplier ? _c('el-tab-pane', {
    attrs: {
      "name": "Product Default Spec",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "playlist-check",
      "size": 18
    }
  }), _vm._v(" Product Specs ")], 1), _c('OrgProductDefaultSpec')], 1) : _vm._e(), _vm.isShowProductTab ? _c('el-tab-pane', {
    attrs: {
      "name": "Product",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "medical-bag",
      "size": 18
    }
  }), _vm._v(" Products ")], 1), _c('OrgProduct', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-product-list',
      expression: "'organisation-product-list'"
    }]
  })], 1) : _vm._e(), _vm.isShowSpecTab ? _c('el-tab-pane', {
    attrs: {
      "name": "Specification",
      "lazy": ""
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "tag",
      "size": 18
    }
  }), _vm._v(" Product Specifications ")], 1), _c('OrgProductSpecGroup', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'organisation-product-spec-list',
      expression: "'organisation-product-spec-list'"
    }]
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }