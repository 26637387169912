var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-auth"
  }, [_c('div', {
    attrs: {
      "id": "main-wrapper"
    }
  }, [_c('Navbar', {
    on: {
      "burger-clicked": function ($event) {
        return _vm.burgerClicked();
      }
    }
  }), _c('div', {
    class: 'page-wrapper ' + _vm.pageWrapperClass()
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('Breadcrumb'), _c('AppMain')], 1)]), _c('Footer')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }