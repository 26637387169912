var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "masthead text-white"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center justify-content-between"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-6 col-lg-4"
  }, [_c('Signin')], 1)])])]), _c('section', {
    staticClass: "section pt-0 position-relative pull-top"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "rounded shadow p-5 bg-white"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4 mb-4 mb-md-0 text-center"
  }, [_c('mdicon', {
    staticClass: "text-success",
    attrs: {
      "name": "database",
      "size": 36
    }
  }), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v("Manages tender process")]), _c('p', {
    staticClass: "regular text-muted"
  }, [_vm._v(" Manages the entire tendering process from the RFT to the invoicing. ")])], 1), _c('div', {
    staticClass: "col-md-4 mb-4 mb-md-0 text-center"
  }, [_c('mdicon', {
    staticClass: "text-success",
    attrs: {
      "name": "account-cog",
      "size": 36
    }
  }), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v("Easy-to-use system")]), _vm._m(1)], 1), _c('div', {
    staticClass: "col-md-4 mb-4 mb-md-0 text-center"
  }, [_c('mdicon', {
    staticClass: "text-success",
    attrs: {
      "name": "timer",
      "size": 36
    }
  }), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v("Improves efficiency")]), _c('p', {
    staticClass: "regular text-muted"
  }, [_vm._v(" Significantly reduces the time taken to review and award bids ")]), _c('p')], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6 col-lg-7"
  }, [_c('div', {
    staticClass: "maintext mb-5"
  }, [_c('h1', {
    staticClass: "mb-3"
  }, [_vm._v("Illuminate GREEN")]), _c('p', [_vm._v(" A tender management system that deals with the tendering of pharmaceutical and healthcare products ")])]), _c('a', {
    staticClass: "btn btn-orange btn-lg text-white",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(" Request Demo "), _c('i', {
    staticClass: "fa fa-angle-right"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "regular text-muted"
  }, [_vm._v(" Allows users to easily create new tenders."), _c('br'), _vm._v("Allows users to easily place bids on open tenders ")]);
}]

export { render, staticRenderFns }