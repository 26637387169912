var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TradeProductForm', {
    ref: "tradeProductForm"
  }, [!_vm.editingObject.source || _vm.editingObject.source !== 'api' ? _c('div', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'trade-product-edit',
      expression: "'trade-product-edit'"
    }],
    staticClass: "card-group mb-3"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body text-center"
  }, [!_vm.editable && !_vm.creating ? _c('el-form-item', [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.enableEdit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil-lock-outline",
      "size": 18
    }
  }), _vm._v(" Edit")], 1)], 1) : _c('el-form-item', [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "content-save",
      "size": 18
    }
  }), _vm._v(" Save")], 1), _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "refresh",
      "size": 18
    }
  }), _vm._v(" Reset")], 1), !_vm.creating ? _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "close-circle-outline",
      "size": 18
    }
  }), _vm._v(" Cancel")], 1) : _vm._e()], 1)], 1)])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }