var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isTableLoading,
      expression: "isTableLoading"
    }],
    attrs: {
      "data": _vm.organisations
    },
    on: {
      "sort-change": _vm.SetSorting
    }
  }, [_vm._l(_vm.columns, function (column, index) {
    var _column$width;
    return _c('el-table-column', {
      key: index,
      attrs: {
        "width": (_column$width = column.width) !== null && _column$width !== void 0 ? _column$width : '',
        "sortable": _vm.columns[index].sortable,
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    });
  }), _c('el-table-column', {
    attrs: {
      "label": "",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'organisation-edit',
            expression: "'organisation-edit'"
          }],
          staticClass: "mx-1",
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push({
                name: 'organisation.edit',
                params: {
                  id: scope.row.id
                }
              });
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "pencil",
            "size": 14
          }
        })], 1), _c('el-popconfirm', {
          staticClass: "mx-1",
          attrs: {
            "confirm-button-text": "Reject",
            "cancel-button-text": "Cancel",
            "confirm-button-type": "danger",
            "icon": "el-icon-info",
            "icon-color": "red",
            "title": "Are you sure to reject this organisation?"
          },
          on: {
            "confirm": function ($event) {
              return _vm.rejectOrganisation(scope.row);
            }
          }
        }, [_c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'organisation-edit',
            expression: "'organisation-edit'"
          }],
          attrs: {
            "slot": "reference",
            "type": "danger",
            "size": "small",
            "icon": "el-icon-close",
            "plain": ""
          },
          slot: "reference"
        }, [_vm._v(" Reject ")])], 1), _c('el-popconfirm', {
          staticClass: "mx-1",
          attrs: {
            "confirm-button-text": "Accept",
            "cancel-button-text": "Cancel",
            "icon": "el-icon-info",
            "icon-color": "primary",
            "title": "Are you sure to accept this?"
          },
          on: {
            "confirm": function ($event) {
              return _vm.acceptOrganisation(scope.row);
            }
          }
        }, [_c('el-button', {
          directives: [{
            name: "permission",
            rawName: "v-permission",
            value: 'organisation-edit',
            expression: "'organisation-edit'"
          }],
          attrs: {
            "slot": "reference",
            "type": "primary",
            "size": "small",
            "plain": "",
            "icon": "el-icon-check"
          },
          slot: "reference"
        }, [_vm._v(" Accept ")])], 1)];
      }
    }])
  })], 2), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('Pagination', {
    attrs: {
      "total_data": _vm.total,
      "limit": _vm.limit,
      "current": _vm.currentPage
    },
    on: {
      "update-table-page": _vm.handleTablePage,
      "update-table-limit": _vm.handleTableLimit
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }