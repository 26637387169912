var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-button', {
    staticClass: "mb-4",
    attrs: {
      "type": "plain",
      "icon": "el-icon-arrow-left",
      "link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Go Back")]), _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "productForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('el-tabs', {
    attrs: {
      "tab-position": "top",
      "type": "border-card"
    },
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "name": "primary"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "receipt",
      "size": 18
    }
  }), _vm._v(" Product Information")], 1), !_vm.creating ? _c('el-form-item', {
    attrs: {
      "label": "UUID",
      "prop": "uuid"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": true
    },
    model: {
      value: _vm.form.uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "uuid", $$v);
      },
      expression: "form.uuid"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "MPUU ID"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": true
    },
    model: {
      value: _vm.form.medicinal_product,
      callback: function ($$v) {
        _vm.$set(_vm.form, "medicinal_product", $$v);
      },
      expression: "form.medicinal_product"
    }
  }), _vm.form.medicinal_products ? _c('div', {
    staticClass: "pl-3"
  }, [_vm._v(" " + _vm._s(_vm.form.medicinal_products.name) + " ")]) : _vm._e()], 1), _c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Container Type",
      "prop": "Container_Type"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.Container_Type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "Container_Type", $$v);
      },
      expression: "form.Container_Type"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Container Qty",
      "prop": "Container_Qty"
    }
  }, [_c('el-input-number', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.form.Container_Qty,
      callback: function ($$v) {
        _vm.$set(_vm.form, "Container_Qty", $$v);
      },
      expression: "form.Container_Qty"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Container Qty Type",
      "prop": "Container_Qty_Type"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.Container_Qty_Type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "Container_Qty_Type", $$v);
      },
      expression: "form.Container_Qty_Type"
    }
  })], 1)], 1), _c('el-tab-pane', {
    attrs: {
      "name": "secondary"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "information",
      "size": 18
    }
  }), _vm._v(" Supporting Information")], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG ID",
      "prop": "ARTG_ID"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_ID,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_ID", $$v);
      },
      expression: "form.ARTG_ID"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Description"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_Description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_Description", $$v);
      },
      expression: "form.ARTG_Description"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG DosageForm"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_DosageForm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_DosageForm", $$v);
      },
      expression: "form.ARTG_DosageForm"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Route of Administration"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_RouteOfAdministration,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_RouteOfAdministration", $$v);
      },
      expression: "form.ARTG_RouteOfAdministration"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Container Storage"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_ContainerStorage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_ContainerStorage", $$v);
      },
      expression: "form.ARTG_ContainerStorage"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Contrainer Type"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_ContainerType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_ContainerType", $$v);
      },
      expression: "form.ARTG_ContainerType"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Poison Schedule"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_PoisonSchedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_PoisonSchedule", $$v);
      },
      expression: "form.ARTG_PoisonSchedule"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "PBS Manufacturer Name"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.PBS_ManufacturerName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "PBS_ManufacturerName", $$v);
      },
      expression: "form.PBS_ManufacturerName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "PBS TPP SCTID"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.PBS_TPP_SCTID,
      callback: function ($$v) {
        _vm.$set(_vm.form, "PBS_TPP_SCTID", $$v);
      },
      expression: "form.PBS_TPP_SCTID"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "PBS Brand"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.PBS_Brand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "PBS_Brand", $$v);
      },
      expression: "form.PBS_Brand"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "PBS Code"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.PBSCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "PBSCode", $$v);
      },
      expression: "form.PBSCode"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "ARTG Description"
    }
  }, [_c('el-input', {
    attrs: {
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.ARTG_Description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ARTG_Description", $$v);
      },
      expression: "form.ARTG_Description"
    }
  })], 1)], 1), _c('el-tab-pane', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'documents-list',
      expression: "'documents-list'"
    }, {
      name: "orgType",
      rawName: "v-orgType",
      value: 'supplier',
      expression: "'supplier'"
    }],
    attrs: {
      "name": "Documents"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('mdicon', {
    attrs: {
      "name": "file-document-multiple",
      "size": 18
    }
  }), _vm._v(" Documents")], 1), _c('Documents')], 1)], 1)], 1)])]), _vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }