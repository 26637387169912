var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }]
  }, [_c('div', {
    staticClass: "card-group mb-3"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-form', {
    ref: "productReportForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "inline": true
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "rft_start_on_from"
    }
  }, [_c('el-date-picker', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "daterange",
      "format": "dd/MM/yyyy",
      "unlink-panels": "",
      "range-separator": "-",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "shortcuts": _vm.shortcuts,
      "size": 'default'
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v("Go")])], 1), _vm.dataOriginal.length > 0 ? _c('div', [_c('h6', [_vm._v("Filter by:")]), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.uuid
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.rft_uuid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_uuid", $$v);
      },
      expression: "form.rft_uuid"
    }
  }, _vm._l(_vm.getUniqueColData('rft_uuid'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.rft_start_on
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.rft_start_on,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_start_on", $$v);
      },
      expression: "form.rft_start_on"
    }
  }, _vm._l(_vm.getUniqueColData('rft_start_on'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": _vm.DateTime.convertVal(item.text, 'date'),
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.requested_product_name
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": "",
      "id": "filter_rft_product"
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.requested_product_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "requested_product_name", $$v);
      },
      expression: "form.requested_product_name"
    }
  }, _vm._l(_vm.getUniqueColData('requested_product_name'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.trade_product_name
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.trade_product_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "trade_product_name", $$v);
      },
      expression: "form.trade_product_name"
    }
  }, _vm._l(_vm.getUniqueColData('trade_product_name'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    });
  }), 1)], 1), _vm.isSupplier ? _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.rft_owner_organisation_name
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.rft_owner_organisation_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rft_owner_organisation_name", $$v);
      },
      expression: "form.rft_owner_organisation_name"
    }
  }, _vm._l(_vm.getUniqueColData('rft_owner_organisation_name'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm.isSupplier ? _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.win_lose
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    on: {
      "change": _vm.handleFilterChange
    },
    model: {
      value: _vm.form.win_lose,
      callback: function ($$v) {
        _vm.$set(_vm.form, "win_lose", $$v);
      },
      expression: "form.win_lose"
    }
  }, _vm._l(_vm.getUniqueColData('win_lose'), function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    });
  }), 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)])])])]), _c('el-card', {
    staticClass: "box-card mb-2"
  }, [_c('el-button', {
    attrs: {
      "size": 'small',
      "type": 'primary',
      "plain": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.reportDownload();
      }
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "microsoft-excel",
      "size": 20
    }
  }), _vm._v(" Download ")], 1)], 1), _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.data,
      "default-sort": {
        prop: 'rft_uuid'
      }
    }
  }, _vm._l(_vm.columns, function (column, index) {
    return _c('el-table-column', {
      key: index,
      attrs: {
        "sortable": _vm.columns[index].sortable,
        "min-width": _vm.columns[index].width,
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "align": column.align,
        "prop": index,
        "filters": column.filters,
        "filter-method": typeof _vm.columns[index].filterCol === 'function' ? _vm.columns[index].filterCol : null,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    }, [_c('template', {
      slot: "header"
    }, [_vm._v(_vm._s(column.label) + " ")])], 2);
  }), 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }