var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "my-3",
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.editingObject.name))]), _c('el-button', {
    attrs: {
      "type": "outline",
      "icon": "el-icon-user"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'organisation.invite'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isBuyer ? "Invite Supplier" : _vm.isSupplier ? "Invite Buyer" : "") + " ")])], 1), _c('OrgTab')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }