var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "productSpecCategoryForm",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "label-width": "120px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Category",
      "prop": "category"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Category",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Description",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Description",
      "readonly": !_vm.editable
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }