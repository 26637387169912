var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-9 col-md-7 col-lg-5 mx-auto"
  }, [_c('ForgotPassword')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }