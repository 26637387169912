var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('b-row', [_c('b-col', [_c('img', {
    staticClass: "toplogo",
    attrs: {
      "src": "/images/logo-white.svg",
      "alt": "Illuminate - Green"
    }
  }), _c('div', {
    staticClass: "pt-3"
  }, [_c('p', {
    staticClass: "text-white"
  }, [_vm._v(" © " + _vm._s(this.year) + " Illuminate Health Consulting. All rights reserved. "), _c('br'), _vm._v(" Level 13, Suite 1a, 465 Victoria Avenue, Chatswood NSW 2067 ")])])]), _c('b-col', [_c('b-nav', [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "text-white nav-link",
    attrs: {
      "to": "/about-us"
    }
  }, [_vm._v("About Us")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "text-white nav-link",
    attrs: {
      "to": "/contact-support"
    }
  }, [_vm._v("Contact Support")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "text-white nav-link",
    attrs: {
      "to": "/terms-of-use"
    }
  }, [_vm._v("Terms of Use")])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "text-white nav-link",
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Privacy Policy")])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }