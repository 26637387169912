var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar navbar-top"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "toplogo",
    attrs: {
      "src": "/images/logo.svg",
      "alt": "Illuminate - Green"
    }
  })])])]);
}]

export { render, staticRenderFns }