var render = function () {
  var _vm$form$rft_name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center my-2"
  }, [_c('div', {
    staticClass: "col-sm-12 mx-auto"
  }, [_c('el-card', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "shadow": "never"
    }
  }, [_c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "label-width": "200px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "RFT Owner"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.organisation_name) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT Name"
    }
  }, [_vm._v(" " + _vm._s((_vm$form$rft_name = _vm.form.rft_name) !== null && _vm$form$rft_name !== void 0 ? _vm$form$rft_name : "N/A") + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Person"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_person) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Phone"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_phone) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Contact Email"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.contact_email) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "Brief Description"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.description) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT Start Date"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.rft_start_on) + " ")]), _c('el-form-item', {
    attrs: {
      "label": "RFT End Date"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.rft_end_on) + " ")])], 1), !_vm.isLoggedIn && _vm.isBidable ? _c('div', {
    staticClass: "text-center"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.isRequestUserDialogOpen = !_vm.isRequestUserDialogOpen;
      }
    }
  }, [_vm._v(" Request Access ")]), _c('RequestUserInvitationModal', {
    attrs: {
      "rft": _vm.rftData
    },
    model: {
      value: _vm.isRequestUserDialogOpen,
      callback: function ($$v) {
        _vm.isRequestUserDialogOpen = $$v;
      },
      expression: "isRequestUserDialogOpen"
    }
  })], 1) : _vm._e(), _vm.isLoggedIn && _vm.isSupplier && _vm.isBidable ? _c('div', {
    staticClass: "text-center"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "disabled": _vm.isSendInvitationLoading
    },
    on: {
      "click": function ($event) {
        return _vm.sendInvitation();
      }
    }
  }, [_vm._v(" Request Invitation ")])], 1) : _vm._e()], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }