var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v("Roles")]), _c('el-collapse', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "name": index
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('strong', [_vm._v(_vm._s(item.name))])]), _vm._l(item.permissions, function (perm, permGroup) {
      return _c('el-card', {
        key: permGroup,
        staticClass: "box-card mb-1"
      }, [_c('div', {
        staticClass: "clearfix",
        attrs: {
          "slot": "header"
        },
        slot: "header"
      }, [_c('span', [_vm._v(_vm._s(permGroup))])]), _c('div', _vm._l(perm, function (isAllowed, permName) {
        return _c('el-tag', {
          key: permName,
          staticClass: "mr-2 mb-2",
          attrs: {
            "type": isAllowed ? 'success' : 'danger',
            "effect": "dark"
          }
        }, [_vm._v(" " + _vm._s(permName) + ": "), isAllowed ? _c('mdicon', {
          attrs: {
            "name": "check-circle-outline"
          }
        }) : _vm._e(), !isAllowed ? _c('mdicon', {
          attrs: {
            "name": "close-circle"
          }
        }) : _vm._e()], 1);
      }), 1)]);
    })], 2);
  }), 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }