var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-button', {
    staticClass: "mb-4",
    attrs: {
      "type": "plain",
      "icon": "el-icon-arrow-left",
      "link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Go Back")]), _c('ProductPacks')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }