var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "id": "medicinal-product-document-modal",
      "size": "lg",
      "title": "Medicinal Product Documents",
      "hide-footer": ""
    },
    on: {
      "hidden": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('Documents')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }