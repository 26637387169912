var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card-group mb-3"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search Medicine ..."
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchHandler.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchKeyword,
      callback: function ($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  }, [_vm.searchKeyword ? _c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-close"
    },
    on: {
      "click": _vm.clearSearch
    },
    slot: "append"
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('b-button', {
    directives: [{
      name: "permission",
      rawName: "v-permission",
      value: 'medicinal-product-create',
      expression: "'medicinal-product-create'"
    }],
    staticClass: "mr-3 float-right",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleMedicineEdit();
      }
    }
  }, [_vm._v(" + Add New Medicine ")])], 1)]), _c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-switch', {
    model: {
      value: _vm.filterSource,
      callback: function ($$v) {
        _vm.filterSource = $$v;
      },
      expression: "filterSource"
    }
  }), _vm._v(" Manually added items only   "), _c('el-switch', {
    model: {
      value: _vm.filterCombination,
      callback: function ($$v) {
        _vm.filterCombination = $$v;
      },
      expression: "filterCombination"
    }
  }), _vm._v(" Exclude combination items ")], 1)])])])]), _c('div', {
    staticClass: "card-group"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "height": "550",
      "data": _vm.data
    },
    on: {
      "sort-change": _vm.SetSorting
    }
  }, [_vm._l(_vm.columns, function (column, index) {
    return _c('el-table-column', {
      key: index,
      attrs: {
        "sortable": _vm.columns[index].sortable,
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    });
  }), _c('el-table-column', {
    attrs: {
      "label": "List product packs of this medicine",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.source === 'manual' ? _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.handleMedicineEdit(scope.row);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "pencil",
            "size": 20
          }
        })], 1) : _vm._e(), _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.selectMedicinalProduct(scope);
            }
          }
        }, [_c('mdicon', {
          attrs: {
            "name": "medical-bag",
            "size": 20
          }
        }), _vm._v(" Product Packs ")], 1), _c('el-button', {
          attrs: {
            "size": "small"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.handleDocumentEdit(scope.row);
            }
          }
        }, [scope.row.documents_count ? _c('span', {
          staticClass: "text-success"
        }, [_c('mdicon', {
          attrs: {
            "name": "file-multiple",
            "size": 20
          }
        })], 1) : _c('span', [_c('mdicon', {
          attrs: {
            "name": "file-upload",
            "size": 20
          }
        })], 1)])];
      }
    }])
  })], 2), _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "total, prev, pager, next, jumper",
      "hide-on-single-page": true,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "total": _vm.currentTotalPage
    },
    on: {
      "current-change": _vm.SetPageNumber
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "medicinal-product-modal"
  }, [_c('MedicinalProductModal', {
    ref: "medicinalProductModal"
  })], 1), _c('div', {
    staticClass: "medicinal-product-document-modal"
  }, [_c('MedicinalProductDocument', {
    ref: "medicinalProductDocument"
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }